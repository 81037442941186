import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaPlaneDeparture, FaSearch, FaCalendarAlt, FaBroadcastTower, FaPaperPlane } from 'react-icons/fa';

const Sidebar = () => {
  const location = useLocation();

  const menuItems = [
    { path: '/dashboard', name: 'Dashboard', icon: <FaHome /> },
    { path: '/flyplans', name: 'Flyplans', icon: <FaPlaneDeparture /> },
    { path: '/search', name: 'Search', icon: <FaSearch /> },
    { path: '/fly-schedule', name: 'Fly Schedule', icon: <FaCalendarAlt /> },
    { path: '/live', name: 'Live', icon: <FaBroadcastTower /> },
    { path: '/telegram-settings', name: 'Telegram Settings', icon: <FaPaperPlane /> },
  ];

  return (
    <div className="w-64 bg-background h-full shadow-lg">
      <div className="p-6">
        <div className="flex items-center justify-center">
          <FaPlaneDeparture className="text-3xl text-primary mr-2" />
          <h2 className="text-xl font-bold bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">Jetwatcher</h2>
        </div>
      </div>
      <nav className="mt-6">
        <ul>
          {menuItems.map((item) => (
            <li key={item.path} className="mb-2">
              <Link
                to={item.path}
                className={`flex items-center px-6 py-3 text-text-dark ${
                  location.pathname === item.path ? 'text-white' : ''
                }`}
              >
                <div className="w-8 h-8 flex items-center justify-center bg-gradient-to-r from-primary to-secondary rounded-md mr-3">
                  {item.icon}
                </div>
                <span className="font-medium">{item.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
