import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { CircularProgress, Select, MenuItem, FormControl, InputLabel, TextField, Button } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import { FaPlane, FaBan, FaThLarge, FaList, FaCalendarPlus } from 'react-icons/fa';
import MapComponent from './MapComponent';
import 'leaflet/dist/leaflet.css';
import { createEvent } from 'ics';

const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function FlySchedule() {
  const [flySchedule, setFlySchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDay, setSelectedDay] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [viewType, setViewType] = useState('grid');

  const fetchFlySchedule = useCallback(async () => {
    try {
      const response = await axios.get('https://jetwatchers.com/api/fly-schedule/', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        params: {
          day: selectedDay,
        },
      });
      console.log('API response:', response.data);
      // Filter flights to show only PROPOSED and CANCELLED
      const filteredFlights = response.data.filter(flight => 
        flight.status === 'PROPOSED' || flight.status === 'CANCELLED'
      ).map(flight => ({
        ...flight,
        origin_lat: parseFloat(flight.origin_lat),
        origin_lon: parseFloat(flight.origin_lon),
        destination_lat: parseFloat(flight.destination_lat),
        destination_lon: parseFloat(flight.destination_lon),
        map_data_available: flight.map_data_available,
      }));
      setFlySchedule(filteredFlights);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching fly schedule:', error);
      setIsLoading(false);
      alert('Error loading flight schedule. Please try again later.');
    }
  }, [selectedDay]);

  useEffect(() => {
    fetchFlySchedule();
  }, [selectedDay, fetchFlySchedule]);

  // Memoize the filtered flights to avoid unnecessary recalculations
  const filteredFlySchedule = useMemo(() => {
    return flySchedule.filter(flight =>
      flight.callsign.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (flight.celebrity_name && flight.celebrity_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      flight.reg.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, flySchedule]);

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleView = () => {
    setViewType(viewType === 'grid' ? 'list' : 'grid');
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'PROPOSED':
        return <FaPlane className="text-green-500" />;
      case 'CANCELLED':
        return <FaBan className="text-red-500" />;
      default:
        return <FaPlane className="text-gray-500" />;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'PROPOSED':
        return 'text-green-500';
      case 'CANCELLED':
        return 'text-red-500';
      default:
        return 'text-gray-300';
    }
  };

  const addToCalendar = (flight) => {
    const start = new Date(flight.dep_time_est);
    const end = new Date(flight.arr_time_est);
    
    const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//JetWatchers//Flight Schedule//EN
BEGIN:VEVENT
UID:${flight.id}@jetwatchers.com
DTSTAMP:${new Date().toISOString().replace(/[-:]/g, '').split('.')[0]}Z
DTSTART:${start.toISOString().replace(/[-:]/g, '').split('.')[0]}Z
DTEND:${end.toISOString().replace(/[-:]/g, '').split('.')[0]}Z
SUMMARY:Flight: ${flight.callsign} - ${flight.origin} to ${flight.destination}
DESCRIPTION:Callsign: ${flight.callsign}\\nRegistration: ${flight.reg}\\nOrigin: ${flight.origin_full} (${flight.origin})\\nDestination: ${flight.destination_full} (${flight.destination})\\nStatus: ${flight.status}${flight.celebrity_name ? `\\nCelebrity: ${flight.celebrity_name}` : ''}
LOCATION:${flight.origin} to ${flight.destination}
END:VEVENT
END:VCALENDAR`;

    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `flight_${flight.callsign}.ics`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const MemoizedMapComponent = useMemo(() => React.memo(MapComponent), []);

  return (
    <div className="flex h-screen bg-background">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-background p-6">
          <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
            <div className="flex space-x-4">
              <FormControl variant="outlined" className="min-w-[120px]">
                <InputLabel id="day-select-label" className="text-white">Day</InputLabel>
                <Select
                  labelId="day-select-label"
                  value={selectedDay}
                  onChange={handleDayChange}
                  label="Day"
                  className="text-white bg-gray-700"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'white',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'lightblue',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'lightblue',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                  }}
                >
                  <MenuItem value="all">All Days</MenuItem>
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="tomorrow">Tomorrow</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                className="min-w-[200px]"
                InputProps={{
                  className: "text-white",
                }}
                InputLabelProps={{
                  className: "text-white",
                }}
              />
              <Button
                variant="outlined"
                onClick={toggleView}
                startIcon={viewType === 'grid' ? <FaList /> : <FaThLarge />}
                className="text-white"
              >
                {viewType === 'grid' ? 'List View' : 'Grid View'}
              </Button>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <CircularProgress />
            </div>
          ) : viewType === 'grid' ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredFlySchedule.map((flight) => (
                <div key={flight.id} className="bg-card rounded-lg shadow-md p-6">
                  {flight.photo && (
                    <img 
                      src={`https://jetwatchers.com/media/flyplan_photos/${flight.photo.split('/').pop()}`}
                      alt={flight.celebrity_name} 
                      className="w-full h-48 object-contain rounded-t-lg mb-4" 
                    />
                  )}
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold text-white">{flight.callsign}</h3>
                    <div className="flex items-center">
                      <span className={`${getStatusColor(flight.status)} mr-2 font-semibold`}>{flight.status}</span>
                      {getStatusIcon(flight.status)}
                    </div>
                  </div>
                  {flight.celebrity_name && (
                    <p className="text-gray-300 mb-2"><span className="font-semibold">Celebrity:</span> {flight.celebrity_name}</p>
                  )}
                  <p className="text-gray-300 mb-2"><span className="font-semibold">Reg:</span> {flight.reg}</p>
                  <p className="text-gray-300 mb-2">
                    <span className="font-semibold">Origin:</span> {flight.origin_full !== 'Unknown, Unknown, Unknown' ? flight.origin_full : ''} ({flight.origin})
                  </p>
                  <p className="text-gray-300 mb-2">
                    <span className="font-semibold">Destination:</span> {flight.destination_full !== 'Unknown, Unknown, Unknown' ? flight.destination_full : ''} ({flight.destination})
                  </p>
                  <p className="text-gray-300 mb-2"><span className="font-semibold">Departure:</span> {new Date(flight.dep_time_est).toLocaleString()}</p>
                  <p className="text-gray-300"><span className="font-semibold">Arrival:</span> {new Date(flight.arr_time_est).toLocaleString()}</p>
                  <div className="mt-4 h-48">
                    {flight.map_data_available ? (
                      <MemoizedMapComponent
                        key={`${flight.id}-${flight.origin}-${flight.destination}`}
                        originLat={flight.origin_lat}
                        originLon={flight.origin_lon}
                        destinationLat={flight.destination_lat}
                        destinationLon={flight.destination_lon}
                      />
                    ) : (
                      <p className="text-gray-300">Map data unavailable for this flight</p>
                    )}
                  </div>
                  <div className="mt-4">
                    <FaCalendarPlus 
                      className="text-blue-500 hover:text-blue-600 cursor-pointer" 
                      onClick={() => addToCalendar(flight)}
                      title="Add to Calendar"
                      size={24}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="space-y-4">
              {filteredFlySchedule.map((flight) => (
                <div key={flight.id} className="bg-card rounded-lg shadow-md p-4 flex items-center">
                  <div className="flex-shrink-0 mr-4">
                    {flight.photo && (
                      <img 
                        src={`https://jetwatchers.com/media/flyplan_photos/${flight.photo.split('/').pop()}`}
                        alt={flight.celebrity_name} 
                        className="w-16 h-16 object-cover rounded-full" 
                      />
                    )}
                  </div>
                  <div className="flex-grow">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-xl font-semibold text-white">{flight.callsign}</h3>
                      <div className="flex items-center">
                        <span className={`${getStatusColor(flight.status)} mr-2 font-semibold`}>{flight.status}</span>
                        {getStatusIcon(flight.status)}
                      </div>
                    </div>
                    <p className="text-gray-300"><span className="font-semibold">Origin:</span> {flight.origin_full !== 'Unknown, Unknown, Unknown' ? flight.origin_full : ''} ({flight.origin})</p>
                    <p className="text-gray-300"><span className="font-semibold">Destination:</span> {flight.destination_full !== 'Unknown, Unknown, Unknown' ? flight.destination_full : ''} ({flight.destination})</p>
                    <p className="text-gray-300"><span className="font-semibold">Departure:</span> {new Date(flight.dep_time_est).toLocaleString()}</p>
                    <div className="mt-2 flex justify-end">
                      <FaCalendarPlus 
                        className="text-blue-500 hover:text-blue-600 cursor-pointer" 
                        onClick={() => addToCalendar(flight)}
                        title="Add to Calendar"
                        size={24}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default FlySchedule;
